@import 'comm.scss';
body{
    padding-top: 0 !important;
}
.banner-swiper{
    .swiper-button-next{
        right: 3%;
    }
    .swiper-button-prev{
        left: 3%;
    }
    .swiper-slide{
        position: relative;
        .container{
            position: absolute;
            left: 50%;
            top: 50%;
            height: 100%;
            z-index: 3;
            transform: translate(-50%, -50%);
        }
        .img{
            display: block;
            width: 100%;
            height: 100vh;
            object-fit: cover;
            transform: scale(1.1);
        }
        .video-js{
            animation: none !important;
        }
        &.swiper-slide-active{
            .info{
                margin-top: 0;
                opacity: 1;
                animation: kv 1s linear;
            }
            .img{
                transform: scale(1);
                animation: img 5s linear;
            }
        }
        .info{
            position: absolute;
            top: 50%;
            left: 0;
            width: 100%;
            z-index: 2;
            opacity: 0;
            margin-top: 0;
            transform: translateY(-50%);
            .s-title{
                font-size: 50px;
                margin-bottom: 10px;
                line-height: 1.2;
                font-weight: bold;
                color: #fff;
            }
            .title{
                font-size: 64px;
                line-height: 1.1;
                font-weight: 800;
                color: #fff;
            }
            .text{
                margin-top: 40px;
                font-size: 18px;
                line-height: 2.2;
                font-weight: bold;
                color: #fff;
            }
        }
        .s-btn{
            margin-top: 40px;
            display: inline-block;
            width: 160px;
            line-height: 50px;
            text-align: center;
            font-size: 14px;
            font-weight: 500;
            border: 1px solid #fff;
            color: #fff;
            transition: all 0.4s;
            border-radius: 5px;
            &:hover{
              background: #f4a000;
              border-color: #f4a000;
            }
        }
    }
    .banner-foot{
        position: absolute;
        bottom: 50px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 2;
        .kv-setp{
            position: absolute;
            top: 50%;
            left: 0;
            width: 25%;
            height: 5px;
            background: #fff;
            z-index: 1;
            transform: translateY(-50%);
        }
    }
    .swiper-pagination {
        font-size: 0;
        position: static;
        transform: none;
        width: auto !important;
        padding: 0 124px;
        white-space: nowrap;
        .swiper-pagination-bullet{
            width: 12px;
            height: 12px;
            border: 1px solid #fff;
            background: transparent;
            border-radius: 0;
            margin: 0 124px !important;
            transform: skewX(-25deg);
            opacity: 1;
            position: relative;
            transition: all 0.3s;
            z-index: 2;
            &.swiper-pagination-bullet-active, &.active{
                background: #fff;
                &::after, &::before{
                    // height: 5px !important;
                    // background: #fff !important;
                }
            }
            &:last-child{
                &::before{
                    position: absolute;
                    top: 50%;
                    left: 100%;
                    width: 248px;
                    height: 1px;
                    background: rgba(255,255,255,0.3);
                    display: block;
                    content: '';
                    transform: translateY(-50%);
                }
            }
            &::after{
                position: absolute;
                top: 50%;
                right: 100%;
                width: 248px;
                height: 1px;
                background: rgba(255,255,255,0.3);
                display: block;
                transform: translateY(-50%);
                content: '';
            }
        }
    }
    
}
@keyframes kv {
    0%{
        opacity: 0;
        margin-top: 10%;
    }
    100% {
        opacity: 1;
        margin-top: 0;
    }
}
@keyframes img {
    0%{
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
}
.product{
    position: relative;
    display: flex;
    height: 900px;
    background: url(../image/index/pro-bg.png);
    background-position: 50% 50% !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    .pro-title{
        position: absolute;
        top: 120px;
        left: 260px;
        z-index: 2;
        font-size: 50px;
        font-weight: bold;
        color: #333;
    }
    .list{
        width: 130px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 1s;
        background: rgba(230, 238, 246, 0.8);
        &:nth-child(odd) {
            background: rgba(255,255,255,0.8);
        }
        &.active{
            flex: 1;
            background: transparent;
            .j-info{
                display: none;
            }
            .pro-info{
                display: block;
            }
        }
    }
    .j-info{
      position: relative;
      padding-bottom: 35px;
      width: 100%;
        .icon{
            display: block;
            width: 65px;
            height: 65px;
            object-fit: contain;
            margin: 0 auto 20px;
        }
        .name{
          position: absolute;
          left: 0;
          top: 85px;
          width: 100%;
          font-size: 16px;
          font-weight:bold;
          text-align: center;
          color: #333;
        }
    }
    .pro-info{
        display: none;
        width: 100%;
        height: 100%;
        padding-top: 250px;
    }
    .info-box{
        height: calc(100% - 260px);
        padding-left: 260px;
        display: flex;
        .icon{
            display: block;
            width: auto;
            height: 65px;
        }
        .info{
            margin-left: 17px;
            .name{
                font-size: 24px;
                color: $color;
                font-weight: bold;
            }
            .text{
                width: 400px;
                margin-top: 20px;
                margin-bottom: 50px;
                font-size: 16px;
                line-height: 1.8;
                font-weight: 500;
                color: #333;
            }
        }
    }
    .product-swiper{
        height: 260px;
        display: flex;
        .swiper-btn{
            width: 100px;
        }
        .pro-btn{
            height: 50%;
            background: rgba(255,255,255,0.5) url(../image/index/swiper-btn.png) no-repeat 50% 50%;
            background-size: 26px 14px;
            &:hover{
                background-color: rgba(0, 84, 166, 0.4);
                background-image: url(../image/index/swiper-btn1.png);
                &.pre-btn{
                    transform: rotateY(180deg);
                }
                &.next-btn{
                    transform: none;
                }
            }
        }
        .next-btn{
            background: rgba(255,255,255,0.5) url(../image/index/swiper-btn.png) no-repeat 50% 50%;
            background-size: 26px 14px;
            transform: rotateY(180deg);
        }
        .product-swiper-container{
            flex: 1;
            background: rgba(255,255,255,0.95);
            .swiper-slide{
                width: 25%;
                display: flex;
                align-items: center;
                justify-content: center;
                &:hover{
                    .pro-img{
                        transform: scale(1.1);
                    }
                }
                .pro-img{
                    display: block;
                    width: 140px;
                    height: 90px;
                    margin: 0 auto;
                    object-fit: contain;
                    transition: all 0.4s;
                }
                .pro-name{
                    margin-top: 20px;
                    font-size: 16px;
                    font-weight: bold;
                    text-align: center;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    color: #333;
                }
            }
        }
    }
}
.en .product .info-box .info .text{
    font-size: 16px;
}
.en .industry-field .field-swiper .swiper-slide{
  &.swiper-slide-active{
    .name{
      font-size: 16px;
    }
    .icon{
      width: 35px;
    }
  }
  .name{
    font-size: 16px;
  }
}
.industry-field{
    position: relative;
    .bg{
        display: block;
        width: 100%;
        height: auto;
    }
    .container{
        position: absolute;
        top: 0;
        left: 50%;
        padding-top: 100px;
        transform: translateX(-50%);
        z-index: 2;
    }
    .title{
        font-size: 50px;
        font-weight: bold;
        color: #fff;
    }
    .field-box{
        margin-top: 50px;
        display: flex;
        align-items: center;
        overflow: hidden;
        height: 400px;
    }
    .field-swiper{
        width: 330px;
        height: 100%;
        border-right: 1px solid rgba(255,255,255,0.4);
        .swiper-slide{
            display: flex;
            height: auto;
            align-items: center;
            padding: 13px 0;
            &.swiper-slide-active{
                .icon{
                    width: 45px;
                    margin-right: 29px;
                }
                .name{
                    font-size: 30px;
                    color: #fff;
                    &::after{
                        display: block;
                    }
                }
            }
            .icon{
                display: block;
                width: 0;
                height: auto;
                transition: all 0.8s;
            }
            .name{
                position: relative;
                flex: 1;
                font-size: 20px;
                font-weight: bold;
                color: rgba(255,255,255,0.7);
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                &::after{
                    display: none;
                    content: '';
                    position: absolute;
                    top: 0;
                    right: 0;
                    width: 3px;
                    height: 100%;
                    background: #fff;
                }
            }
        }
    }
    .info-box{
        flex: 1;
        position: relative;
        height: 100%;
        .info-list{
            display: flex;
            align-items: center;
            // position: absolute;
            width: 590px;
            // left: 50%;
            margin: 0 auto;
            overflow-y: auto;
            @include scroll(#fff);
            // transform: translateX(-50%);
            .text{
                font-size: 16px;
                line-height: 1.9;
                font-weight: bold;
                color: #fff;
            }
            .more-comm{
                margin-top: 50px;
                .txt, svg{
                    color: #fff;
                }
                &::after{
                    background: #fff !important;
                }
                &:hover{
                    .txt,svg{
                        color: #fff !important;
                    }
                }
            }
        }
    }
}
.en .industry-field .field-swiper{
    width: 400px;
}
.en .industry-field .info-box .info-list .text{
    font-size: 16px;
}
.news{
    width: 1600px;
    margin: 95px auto 110px;
    text-align: center;
    .title{
        font-size: 42px;
        font-weight: bold;
        text-align: center;
        margin-bottom: 50px;
        color: #333;
    }
    .news-box{
        font-size: 0;
        margin: 0 -20px 55px;
        .list{
            width: 50%;
            display: inline-block;
            padding: 0 20px;
            font-size: 0;
            vertical-align: top;
            .img{
                overflow: hidden;
                img{
                    display: block;
                    width: 100%;
                    height: 460px;
                    object-fit: cover;
                    transition: all 0.4s;
                }
            }
            .new-head{
                margin-top: 30px;
                display: flex;
                align-items: center;
                .tag{
                    padding: 8px 12px;
                    line-height: 1;
                    font-size: 14px;
                    border-radius: 5px;
                    background: $color;
                    color: #fff;
                }
                .time{
                    margin-left: 20px;
                    font-size: 14px;
                    color: #666;
                }
            }
            .name{
                margin-top: 15px;
                font-size: 24px;
                line-height: 1.4;
                font-weight: bold;
                text-align: left;
                color: #333;
            }
            .text{
                margin-top: 15px;
                font-size: 16px;
                line-height: 1.8;
                text-align: left;
                color: #666;
            }
            &:nth-child(2) {
                margin: 0 -20px;
            }
            &:nth-child(1) {
                &:hover{
                    .img img{
                        transform: scale(1.1);
                    }
                }
            }
        }
        .s-list{
            display: inline-block;
            width: 50%;
            padding: 0 20px;
            margin-bottom: 40px;
            vertical-align: top;
            &:hover{
                .img img{
                    transform: scale(1.1);
                }
            }
            .img{
                img{
                    height: 207px;
                }
            }
            .new-head{
                margin-top: 10px;
            }
            .name{
                font-size: 18px;
            }
        }
    }
    .more-comm svg{
        color: #333;
    }
}
.en {
    .industry-field .info-box .info-list .text, .banner-swiper .swiper-slide .info .text{
        font-weight: 400;
    }
}
@media (max-width: 1460px) {
  .en .industry-field .field-swiper .swiper-slide.swiper-slide-active .name, .en .product .info-box .info .text {
    font-size: 16px;
  }
  .en .industry-field .field-swiper .swiper-slide .name{
    font-size: 16px;
  }
  .en .industry-field .info-box .info-list .text{
    font-size: 16px;
  }
  .industry-field .info-box .info-list{
      width: 75%;
  }
  .en .product .info-box .info .name{
    font-size: 18px;
  }
  .en .product .info-box .info .text{
    width: 55%;
  }
}
@media (max-width: 640px) {
  .banner-swiper .swiper-slide .s-btn{
        width: auto;
        font-size: 12px;
        padding: 8px 20px;
    }
    .banner-swiper .swiper-slide .info .s-title{
        font-size: 16px;
    }
    .banner-swiper .swiper-slide:nth-child(4) {
      top: 30%;
    }
    .banner-swiper .swiper-slide .info{
        padding: 0 7%;
        text-align: center;
        top: 36%;
        .title{
            font-size: 24px;
        }
        .text{
            margin-top: 10px;
            font-size: 14px;
        }
    }
    .banner-swiper .swiper-pagination .swiper-pagination-bullet{
        width: 6px;
        height: 6px;
    }
    .banner-swiper{
      .swiper-button-prev,.swiper-button-next{
        width: 14px;
        height: 22px;
        background-size: 14px 22px;
      }
    }
    .product .product-swiper .product-swiper-container .swiper-slide{
        width: 100%;
        .pro-img{
            max-width: none;
            max-height: none;
            width: 80px;
            height: 40px;
            object-fit: contain;
        }
        .pro-name{
            font-size: 12px;
            margin-top: 6px;
        }
    }
    .product .j-info {
      padding-bottom: 50px;
    }
    .product .j-info .icon{
        width: 20px;
        height: 20px;
    }
    .product .j-info .name{
        font-size: 12px;
        width: 14px;
        margin: 0 auto;
        text-align: center;
        left: 50%;
        top: 25px;
        transform: translateX(-50%);
    }
    .en .product .j-info{ padding-bottom: 0;}
    .en .product .j-info .name{
        display: none;
    }
    .product{
        height: 450px;
        .list{
            width: 45px;
        }
        .product-swiper{
            height: 100px;
            .swiper-btn{
                width: 40px;
            }
        }
        .pro-title{
            top: 34px;
            left: 15px;
            font-size: 18px;
        }
        .product-swiper{
            .pro-btn{
                background-size: 10px 5px;
            }
        }
        .pro-info{
            padding-top: 80px;
        }
        .info-box{
            padding-left: 15px;
            height: calc(100% - 100px);
            .icon{
                display: block;
                height: 20px;
            }
            .info{
                .name{
                    font-size: 16px;
                }
                .text{
                    width: 90% !important;
                    font-size: 14px !important;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                    overflow: hidden;
                    margin: 5px 0 10px;
                }
            }
        }
    }
    .industry-field .title{
        font-size: 18px;
    }
    .industry-field .bg{
        height: 400px;
        object-fit: cover;
    }
    .industry-field .field-box{
        height: 250px;
        margin-top: 30px;
    }
    .industry-field .field-swiper{
        width: 95px !important;
    }
    .industry-field .field-swiper .swiper-slide.swiper-slide-active {
        .icon{
            width: 15px;
        }
        .name{
            font-size: 12px !important;
        }
    }
    .industry-field .info-box .info-list .text{
        font-size: 12px !important;
    }
    .industry-field .field-swiper .swiper-slide .name{
        font-size: 12px !important;
    }
    .industry-field .info-box .info-list{
        width: 90%;
    }
    .industry-field .container{
        padding-top: 30px;
    }
    .news .title{
        font-size: 18px;
    }
    .news .news-box .list{
        width: 100%;
    }
    .news .news-box .list .img img{
        height: 120px;
    }
    .news .news-box .list .new-head {
        .tag{
            padding: 5px;
            font-size: 12px;
        }
        .time{
            font-size: 12px;
            margin-left: 10px;
        }
    }
    .news .news-box .list .name,.news .news-box .list .text{
        margin-top: 5px;
        font-size: 14px;
    }
    .news .news-box .s-list{
        margin-top: 10px;
    }
    .news{
        width: 100%;
        padding: 0 15px;
    }
    .banner-swiper .banner-foot .kv-step{
        height: 2px;
    }
    .industry-field .info-box .info-list .text{
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 7;
        overflow: hidden;
    }
    .en .industry-field .field-swiper .swiper-slide.swiper-slide-active .icon{
        display: none;
        width: 0 !important;
    }
}